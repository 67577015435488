import React, { useState, useEffect } from "react";
import { apiURL } from "./apiConnection.mjs";
import { Link } from "react-router-dom";

function GameSelect() {
    // Program variables
    const [games, setGames] = useState(null);
    const [selectedGame, setSelectedGame] = useState(null);

    // init
    useEffect(() => {
        getGames();
    }, []);

    // api functions
    async function getGames() {
        const response = await fetch(`${apiURL}/get_games`);
        const data = await response.json();

        setGames(data);

        return
    }

    return (
        <div>
            {selectedGame === null && (
                games && (
                    <div className="button-selection">
                        {games.map(game => (
                            <div className="game-box box-shadow" key={game.event_id}>
                                <p>{game.name}</p>
                                <p>{game.start_timestamp}</p>
                                <button className="school-button box-shadow hoverable" onClick={() => setSelectedGame(game)}>Manage</button>
                            </div>
                        ))}
                    </div>
                )
            )}
            {selectedGame && (
                <div className="app-home">
                    <h1>{selectedGame.name}</h1>
                    <Link className="link-link" to={`scoreboard/${selectedGame.event_id}`}>
                        <h2 className="link-text">Scoreboard</h2>
                    </Link>
                    <Link className="link-link" to={`log/${selectedGame.event_id}`}>
                        <h2 className="link-text">Log Game</h2>
                    </Link>
                </div>
            )}
        </div>
    );
}

export default GameSelect