import React, { useState, useEffect } from "react";
import { FormControl, Button, TextField, IconButton,  } from "@mui/material";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiURL } from "./apiConnection.mjs";

function SchoolRoster () {
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [schools, setSchools] = useState(null);
    const [players, setPlayers] = useState(null);
    const [playerNumber, setPlayerNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [playerId, setPlayerId] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    // const [errMessage, setErrMessage] = useState(null);

    // init
    useEffect(() => {
        getSchools();
    }, []);

    // api functions

    async function getSchools() {
        const response = await fetch(`${apiURL}/school_sports`);
        const data = await response.json();

        setSchools(data);

        return
    }

    async function getPlayers(school_sport_id) {
        const response = await fetch(`${apiURL}/players/${school_sport_id}`)
        const data = await response.json();

        setPlayers(data);

        return
    }

    async function addPlayer() {
        const response = await fetch(`${apiURL}/event_logging/add_player?first_name=${firstName}&last_name=${lastName}&number=${playerNumber}&school=${selectedSchool.school_sport_id}`);

        return response
    }

    async function editPlayer() {
        const response = await fetch(`${apiURL}/event_logging/add_player?player_id=${playerId}&first_name=${firstName}&last_name=${lastName}&number=${playerNumber}&school=${selectedSchool.school_sport_id}`);

        return response
    }

    async function removePlayer() {
        const response = await fetch(`${apiURL}/event_logging/remove_player?player_id=${playerId}`);

        return response
    }

    // Utility
    function clearForm() {
        setFirstName('');
        setLastName('');
        setPlayerNumber('');
        setPlayerId(0);
    }

    // Event Listeners
    const handleSchoolSelect = (school) => {
        setSelectedSchool(school);

        getPlayers(school.school_sport_id);
    }

    const handleEditButton = (player) => {
        setIsEditing(true);
        
        setFirstName(player.first_name);
        setLastName(player.last_name);
        setPlayerNumber(player.number);
        setPlayerId(player.player_id);
    }

    const handleDeleteButton = (player) => {
        setIsEditing(true);
        setIsDeleting(true);

        setPlayerId(player.player_id);
        setFirstName(player.first_name);
        setLastName(player.last_name);
        setPlayerNumber(player.number);
    }

    const handleDelete = async () => {
        const response = await removePlayer();

        if (response.status === 201) {
            clearForm();
            setIsDeleting(false);
            setIsEditing(false);
            getPlayers(selectedSchool.school_sport_id);
        }
    }

    const handleSaveChanges = async () => {
        const response = await editPlayer();

        if (response.status === 201) {
            clearForm();
            setIsEditing(false);
            getPlayers(selectedSchool.school_sport_id);
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const response = await addPlayer();

        if (response.status === 201) {
            clearForm();

            getPlayers(selectedSchool.school_sport_id);
        }
        
    }

    return (
        <main>
            <div className={isEditing ? 'overlay' : 'd-none'}>
                    
            </div>
            {selectedSchool == null && (
                schools && (
                    <div className="button-selection">
                        {schools.map(school => (
                            <button className="school-button box-shadow hoverable" key={school.school_sport_id} onClick={() => handleSchoolSelect(school)}>{school.name}</button>
                        ))}
                    </div>
            ))}
            {isDeleting && (
                <div id="confirmation-box">
                    <p className="confirmation-statement">Are you sure you want to delete #{playerNumber} {firstName} {lastName}?</p>
                    <p className="warning-message">This will remove all records of this player and will not be retrievable.</p>
                    <div>
                        <div className="button-left">
                            <Button variant="contained" onClick={() => {setIsDeleting(false); setIsEditing(false); clearForm();}}>Cancel</Button>
                        </div>
                        <div className="button-right">
                            <IconButton aria-label="delete" size="large" onClick={handleDelete}>
                                <DeleteIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            {selectedSchool && (
                <div id="roster-view" className="classic-view">
                    <div id="form-view">
                        {!isDeleting && (
                            <form id="player-form" onSubmit={handleFormSubmit}>
                                <FormControl variant="standard" className="form-input-group">
                                    <TextField variant="outlined" label="Player Number" type="number" className="input" name="playerNum" value={playerNumber} onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const newValue = inputValue === '' ? undefined : parseInt(inputValue, 10);
                                        setPlayerNumber(newValue);
                                    }} />
                                    <TextField variant="outlined" label="First Name" type="text" className="input" name="firstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                    <TextField variant="outlined" label="Last Name" type="text" className="input" name="lastName" value={lastName} onChange={(event) => setLastName(event.target.value)} />

                                    {!isEditing && (
                                        <Button type="submit" variant="contained">Add Player</Button>
                                    )}
                                    {isEditing && (
                                        <div>
                                            <Button variant="text" onClick={() => {setIsEditing(false); clearForm();}}>Cancel</Button>
                                            <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
                                        </div>
                                    )}
                                    
                                </FormControl>
                                
                            </form>
                        )}
                    </div>
                    <div id="data-view" className={isEditing ? 'disable' : 'normal'}>
                        {players && (
                            <div id="roster" className="box-shadow">
                                <div>
                                    <h2>{selectedSchool.name} Roster</h2>
                                </div>
                                {players && (
                                    <div id="players">
                                        {players.map(player => (
                                            <div className="player-div" key={player.player_id}>
                                                <p>{player.first_name}</p>
                                                <p>{player.last_name}</p>
                                                <p>{player.number}</p>
                                                <div className="icons">
                                                    <IconButton aria-label="edit" size="small" onClick={() => handleEditButton(player)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" size="small" onClick={() => handleDeleteButton(player)}>
                                                        <PersonRemoveIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </main>
    );
}

export default SchoolRoster;
