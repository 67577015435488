import React, { useState, useEffect } from "react";
import { FormControl, Button, TextField, IconButton,  } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { apiURL } from "./apiConnection.mjs";

let ran = false;
function ActionTypeWizard () {
    const [actions, setActions] = useState(null);
    const [actionName, setActionName] = useState('');
    const [actionId, setActionId] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (!ran) {
            getActions();
            ran = true;
        }
    }, []);

    async function getActions() {
        const response = await fetch(`${apiURL}/action_types`);
        const data = await response.json();

        setActions(data);

        return
    }

    async function addAction() {
        const response = await fetch(`${apiURL}/event_logging/add_event_type?name=${actionName}`);

        return response
    }

    async function editAction() {
        const response = await fetch(`${apiURL}/event_logging/add_event_type?name=${actionName}&action_id=${actionId}`);

        return response
    }

    async function removeAction() {
        const response = await fetch(`${apiURL}/event_logging/remove_event_type?event_type_id=${actionId}`);

        return response
    }

    function clearForm() {
        setActionId(0);
        setActionName('');
    }

    const handleEditButton = (action) => {
        setIsEditing(true);
        
        setActionName(action.name);
        setActionId(action.event_type_id);
    }

    const handleDeleteButton = (action) => {
        setIsEditing(true);
        setIsDeleting(true);

        setActionId(action.event_type_id);
        setActionName(action.name);
    }

    const handleDelete = async () => {
        const response = await removeAction();

        if (response.status === 201) {
            clearForm();
            setIsDeleting(false);
            setIsEditing(false);
            getActions();
        }
    }

    const handleSaveChanges = async () => {
        const response = await editAction();

        if (response.status === 201) {
            clearForm();
            setIsEditing(false);
            getActions();
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const response = await addAction();

        if (response.status === 201) {
            clearForm();

            getActions();
        }
        
    }

    return (
        <main>
            <div className={isEditing ? 'overlay' : 'd-none'}>
                    
            </div>
            {isDeleting && (
                <div id="confirmation-box">
                    <p className="confirmation-statement">Are you sure you want to delete {actionName}?</p>
                    <p className="warning-message">This will remove all records associated with this action and will not be retrievable.</p>
                    <div>
                        <div className="button-left">
                            <Button variant="contained" onClick={() => {setIsDeleting(false); setIsEditing(false); clearForm();}}>Cancel</Button>
                        </div>
                        <div className="button-right">
                            <IconButton aria-label="delete" size="large" onClick={handleDelete}>
                                <DeleteIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            <div className="classic-view">
                <div id="form-view">
                    {!isDeleting && (
                        <form id="player-form" onSubmit={handleFormSubmit}>
                            <FormControl variant="standard" className="form-input-group">
                                <TextField variant="outlined" label="Action Name" type="text" className="input" name="actionName" value={actionName} onChange={(event) => setActionName(event.target.value)} />

                                {!isEditing && (
                                    <Button type="submit" variant="contained">Add Action</Button>
                                )}
                                {isEditing && (
                                    <div>
                                        <Button variant="text" onClick={() => {setIsEditing(false); clearForm();}}>Cancel</Button>
                                        <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
                                    </div>
                                )}
                                
                            </FormControl>
                            
                        </form>
                    )}
                </div>
                <div id="data-view" className={isEditing ? 'disable' : 'normal'}>
                    <div id="data-box" className="box-shadow">
                        <div>
                            <h2>Actions</h2>
                        </div>
                        {actions && (
                            <div>
                                {actions.map(action => (
                                    <div className="action-div" key={action.event_type_id}>
                                        <p>{action.name}</p>
                                        <div className="icons">
                                            <IconButton aria-label="edit" size="small" onClick={() => handleEditButton(action)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteButton(action)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ActionTypeWizard;