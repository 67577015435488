import React, { useState, useEffect } from "react";
import { FormControl, Button, TextField, IconButton,  } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { apiURL } from "./apiConnection.mjs";

function PenaltyWizard () {
    const [penalties, setPenalties] = useState(null);
    const [penName, setPenName] = useState('');
    const [yardage, setYardage] = useState('');
    const [penaltyId, setPenaltyId] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        getPenalties();
    }, []);

    async function getPenalties() {
        const response = await fetch(`${apiURL}/get_penalties`);
        const data = await response.json();

        setPenalties(data);

        return
    }

    async function addPenalty() {
        const response = await fetch(`${apiURL}/event_logging/add_penalty?name=${penName}&yardage=${yardage}&sport=1`);

        return response
    }

    async function editPenalty() {
        const response = await fetch(`${apiURL}/event_logging/add_penalty?penalty_id=${penaltyId}&name=${penName}&yardage=${yardage}&sport=1`);

        return response
    }

    async function removePenalty() {
        const response = await fetch(`${apiURL}/event_logging/remove_penalty?penalty_id=${penaltyId}`);

        return response
    }

    function clearForm() {
        setPenName('');
        setYardage('');
        setPenaltyId(0);
    }

    const handleEditButton = (penalty) => {
        setIsEditing(true);
        
        setPenName(penalty.name);
        setYardage(penalty.yardage);
        setPenaltyId(penalty.penalty_id);
    }

    const handleDeleteButton = (penalty) => {
        setIsEditing(true);
        setIsDeleting(true);

        setPenName(penalty.name);
        setYardage(penalty.yardage);
        setPenaltyId(penalty.penalty_id);
    }

    const handleDelete = async () => {
        const response = await removePenalty();

        if (response.status === 201) {
            clearForm();
            setIsDeleting(false);
            setIsEditing(false);
            getPenalties();
        }
    }

    const handleSaveChanges = async () => {
        const response = await editPenalty();

        if (response.status === 201) {
            clearForm();
            setIsEditing(false);
            getPenalties();
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const response = await addPenalty();

        if (response.status === 201) {
            clearForm();

            getPenalties();
        }
        
    }

    return (
        <main>
            <div className={isEditing ? 'overlay' : 'd-none'}>
                    
            </div>
            {isDeleting && (
                <div id="confirmation-box">
                    <p className="confirmation-statement">Are you sure you want to delete {penName}?</p>
                    <p className="warning-message">This will remove all records associated with this penalty and will not be retrievable.</p>
                    <div>
                        <div className="button-left">
                            <Button variant="contained" onClick={() => {setIsDeleting(false); setIsEditing(false); clearForm();}}>Cancel</Button>
                        </div>
                        <div className="button-right">
                            <IconButton aria-label="delete" size="large" onClick={handleDelete}>
                                <DeleteIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            <div className="classic-view">
                <div id="form-view">
                    {!isDeleting && (
                        <form id="player-form" onSubmit={handleFormSubmit}>
                            <FormControl variant="standard" className="form-input-group">
                                <TextField variant="outlined" label="Penalty Name" type="text" className="input" name="PenName" value={penName} onChange={(event) => setPenName(event.target.value)} />
                                <TextField variant="outlined" label="Penalty Yardage" type="number" className="input" name="yardage" value={yardage} onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const newValue = inputValue === '' ? undefined : parseInt(inputValue, 10);
                                    setYardage(newValue);
                                }} />

                                {!isEditing && (
                                    <Button type="submit" variant="contained">Add Penalty</Button>
                                )}
                                {isEditing && (
                                    <div>
                                        <Button variant="text" onClick={() => {setIsEditing(false); clearForm();}}>Cancel</Button>
                                        <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
                                    </div>
                                )}
                                
                            </FormControl>
                            
                        </form>
                    )}
                </div>
                <div id="data-view" className={isEditing ? 'disable' : 'normal'}>
                    <div id="data-box" className="box-shadow">
                        <div>
                            <h2>Penalties</h2>
                        </div>
                        {penalties && (
                            <div>
                                {penalties.map(penalty => (
                                    <div className="penalty-div" key={penalty.penalty_id}>
                                        <p>{penalty.name}</p>
                                        <p>{penalty.yardage}</p>
                                        <div className="icons">
                                            <IconButton aria-label="edit" size="small" onClick={() => handleEditButton(penalty)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteButton(penalty)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PenaltyWizard;