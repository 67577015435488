import { Link } from 'react-router-dom';

function Home() {
    return (
        <main className="app-home">
            <Link className="link-link" to="/game">
                <h2 className="link-text">Log Game</h2>
            </Link>
            <Link className="link-link" to="/team-roster">
                <h2 className="link-text">Team Roster</h2>
            </Link>
            <Link className="link-link" to="/action-type-wizard">
                <h2 className="link-text">Action Type Wizard</h2>
            </Link>
            <Link className="link-link" to="/penalty-wizard">
                <h2 className="link-text">Penalty Wizard</h2>
            </Link>
        </main>
    );
}

export default Home;
