import { Outlet, Link } from "react-router-dom";
import logo from "../images/svilogo1.png";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

function GameHeader() {
    let currentURL = window.location.href;
    let { id } = useParams();
    let page = '';

    if (currentURL.includes('scoreboard')) {
        page = 'scoreboard';
    } else if (currentURL.includes('log')) {
        page = 'log';
    }

    return (
        <>
            <header>
                {id !== undefined && (
                    <Link to="/game">
                        <Button size="large">Switch Game</Button>
                    </Link>
                )}
                <Link to="/">
                    <img src={logo} alt="SVILive Logo" width="200" />
                </Link>
                {page === 'scoreboard' && (
                    <Link to={`/game/log/${id}`}>
                        <Button size="large">Logs</Button>
                    </Link>
                )}
                {page === 'log' && (
                    <Link to={`/game/scoreboard/${id}`}>
                        <Button size="large">Scoreboard</Button>
                    </Link>
                )}
                
            </header>

            <Outlet />
        </>
    );
}

export default GameHeader;