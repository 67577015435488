import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import GameHeader from './components/GameHeader';
import Home from './views/home';
import LogGame from './views/log_game';
import SchoolRoster from './views/school_roster';
import ActionTypeWizard from './views/action_type_wizard';
import PenaltyWizard from './views/penalty_wizard';
import Scoreboard from './views/scoreboard';
import GameSelect from './views/game_selection';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          {/* <Route path="log-game" element={<GameSelect />} />
          <Route path="scoreboard/:id" element={<Scoreboard />} />
          <Route path="log-game/:id" element={<LogGame />} /> */}
          <Route path="team-roster" element={<SchoolRoster />} />
          <Route path="action-type-wizard" element={<ActionTypeWizard />} />
          <Route path="penalty-wizard" element={<PenaltyWizard />} />
        </Route>
        <Route path="/game" element={<GameHeader />}>
          <Route index element={<GameSelect />} />
          <Route path="scoreboard/:id" element={<Scoreboard />} />
          <Route path="log/:id" element={<LogGame />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
