import React, { useState, useEffect } from "react";
import { Button, ToggleButtonGroup, ToggleButton, TextField, Fab } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { apiURL, getGameInfo } from "./apiConnection.mjs";
import { useParams } from "react-router-dom";

function Scoreboard () {
    const [selectedGame, setSelectedGame] = useState(null);
    const [scoreboard, setScoreboard] = useState(null);
    
    // form variables
    const [type, setType] = useState('team1_score');
    const [newVal, setNewVal] = useState('');
    

    let { id } = useParams();

    // init
    useEffect(() => {
        init();
        
    }, [id]);

    // api functions
    async function init() {
        const gameData = await getGameInfo(id);
        setSelectedGame(gameData[0]);

        getScoreboard();

        return
    }

    async function getScoreboard() {
        const response = await fetch(`${apiURL}/event_logging/scoreboard/${id}`)
        const data = await response.json();
   
        setScoreboard(data[0].item.scoreboard);

        return
    }

    async function updateScore(component, value) {
        component = component || type;
        if (value !== 0) {
            value = value || newVal;
        }

        const response = await fetch(`${apiURL}/event_logging/scoreboard/update?event_id=${id}&component=${component}&value=${value}`);

        return response
    }

    // utility
    

    // Event Listeners
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const response = await updateScore();

        if (response.status === 201) {
            setNewVal('');
        }
        if (response) {
            getScoreboard();
        }
    }

    const handleEdit = async () => {
        const response = await updateScore('final_flag', 0);

        if (response) {
            getScoreboard();
        }
    }

    const handleGameComplete = async () => {
        const response = await updateScore('final_flag', 1);

        if (response) {
            getScoreboard();
        }
    }

    // Need to fix the edit final flag to 0
    
    return (
        <main>
            {selectedGame && (
                scoreboard && (
                    <div className="split-element-horizontal max-size">
                        {scoreboard.final_flag === 1 && (
                            <section className="flex-vertical">
                                <h1>Final Score</h1>
                                <Fab variant="extended" onClick={handleEdit}>
                                    <EditIcon sx={{ mr: 1 }} />
                                    Edit Game
                                </Fab>
                            </section>
                        )}
                        <section id="scoreboard-parent" className="flex-horizontal text-center">
                            <div>
                                <h2>{selectedGame.home_team}</h2>
                                <p className="box-shadow">{scoreboard.team1_score}</p>
                            </div>
                            <div>
                                <h2>{selectedGame.away_team}</h2>
                                <p className="box-shadow">{scoreboard.team2_score}</p>
                            </div>
                            <div>
                                <h2>Quarter</h2>
                                <p className="box-shadow">{scoreboard.quarter}</p>
                            </div>
                        </section>
                        {scoreboard.final_flag !== 1 && (
                            <section className="update-section">
                                <div className="complete-button">
                                    <Fab variant="extended" onClick={handleGameComplete}>
                                        <CheckCircleIcon sx={{ mr: 1 }} />
                                        Game Complete
                                    </Fab>
                                </div>
                                <form className="flex-vertical" onSubmit={handleFormSubmit}>
                                    <ToggleButtonGroup
                                        value={type}
                                        exclusive
                                        onChange={(event, newType) => {
                                            if (newType !== null) {
                                                setType(newType);
                                            }
                                        }}
                                    >
                                        <ToggleButton value={"team1_score"}>
                                            <p>{selectedGame.home_team}</p>
                                        </ToggleButton>
                                        <ToggleButton value={"team2_score"}>
                                            <p>{selectedGame.away_team}</p>
                                        </ToggleButton>
                                        <ToggleButton value={"quarter"}>
                                            <p>Quarter</p>
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                    <TextField variant="outlined" label="New Value" type="number" className="input" name="newVal" value={newVal} onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const newValue = inputValue === '' ? undefined : parseInt(inputValue, 10);

                                        if (newVal >= 0) {
                                            setNewVal(newValue);
                                        }
                                    }} />

                                    <Button type="submit" variant="contained">Update Score</Button>
                                </form>
                            </section>
                        )}
                    </div>
            ))}
        </main>
    );
}

export default Scoreboard;