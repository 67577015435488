import { Outlet, Link } from "react-router-dom";
import logo from "../images/svilogo1.png";

function Header() {
    return (
        <>
            <header>
                
                <Link to="/">
                    <img src={logo} alt="SVILive Logo" width="200" />
                </Link>
            </header>

            <Outlet />
        </>
    );
}

export default Header;