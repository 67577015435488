import React, { useState, useEffect } from "react";
import { FormControl, Button, TextField, IconButton, ToggleButtonGroup, ToggleButton, InputLabel, Select, MenuItem, ButtonGroup } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiURL } from "./apiConnection.mjs";
import { useParams } from "react-router-dom";

function LogGame () {
    // API Data
    const [logs, setLogs] = useState(null);
    const [team1Players, setTeam1Players] = useState([]);
    const [team2Players, setTeam2Players] = useState([]);
    const [actions, setActions] = useState(null);
    const [penalties, setPenalties] = useState(null);

    // Utility
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    // const [errMessage, setErrMessage] = useState(null);

    // Form Data
    const [selectedGame, setSelectedGame] = useState(null);
    const [schoolId, setSchoolId] = useState('');
    const [logId, setLogId] = useState(0);
    const [playerId, setPlayerId] = useState('');
    const [team, setTeam] = useState('');
    const [quarterBackId, setQuarterBackId] = useState('');
    const [actionId, setActionId] = useState('');
    const [yardage, setYardage] = useState('');
    const [penaltyId, setPenaltyId] = useState('');
    const [penaltyStatus, setPenaltyStatus] = useState('');

    let { id } = useParams();

    // api functions
    useEffect(() => {
        getGameInfo(id)
        
    }, [id]);

    useEffect(() => {
        if (selectedGame) {
            init(selectedGame);
        }
        
    }, [selectedGame]);

    // async function getGames() {
    //     const response = await fetch(`${apiURL}/get_games`);
    //     const data = await response.json();

    //     setGames(data);

    //     return
    // }

    async function getGameInfo(game_id) {
        const response = await fetch(`${apiURL}/get_game_info/${game_id}`);
        const data = await response.json();

        setSelectedGame(data[0]);

        return
    }

    async function getPenalties() {
        const response = await fetch(`${apiURL}/get_penalties`);
        const data = await response.json();

        setPenalties(data);

        return
    }

    async function getPlayers(playerSchoolId, team) {
        const response = await fetch(`${apiURL}/players/${playerSchoolId}`);
        const data = await response.json();

        if (team === 'Home') {
            setTeam1Players(data);
        } else if (team === 'Away') {
            setTeam2Players(data);
        }

        return
    }

    async function getActions() {
        const response = await fetch(`${apiURL}/action_types`);
        const data = await response.json();

        setActions(data);

        return
    }

    async function getLogs(eventId) {
        const response = await fetch(`${apiURL}/get_logs/${eventId}`);
        const data = await response.json();

        const reversedData = data.reverse();

        setLogs(reversedData);

        return
    }

    async function addLog() {
        const parameters = get_url_parameters();

        const response = await fetch(`${apiURL}/event_logging/add_log?${parameters}`);
        
        return response
    }

    async function editLog() {
        const parameters = get_url_parameters();

        const response = await fetch(`${apiURL}/event_logging/add_log?log_id=${logId}&${parameters}`);

        return response
    }

    async function removeLog() {
        const response = await fetch(`${apiURL}/event_logging/remove_log?log_id=${logId}`);

        return response
    }

    // Utility
    function init(game) {
        setSchoolId(game.home_team_id)

        getPenalties();
        getActions();
        if (game.home_partnered === 1){
            getPlayers(game.home_team_id, 'Home');
        } else if (game.away_partnered === 1) {
            getPlayers(game.away_team_id, 'Away');
        }

        getLogs(game.event_id);
        
    }

    function get_url_parameters() {
        let valuePairs = [];
        const keyValues = {
            event_id: selectedGame.event_id,
            school_id: schoolId,
            player_id: playerId,
            team: team,
            quarter_back_id: quarterBackId,
            event_type_id: actionId,
            yardage: yardage,
            penalty_id: penaltyId,
            penalty_status: penaltyStatus
        }

        Object.entries(keyValues).forEach(([key, value]) => {
            if (value !== "" && value !== 0 && value !== null) {
                valuePairs.push(`${key}=${value}`)
            }
        })

        const combinedString = valuePairs.join('&');

        return combinedString
    }

    // Event Listeners

    function clearForm() {
        setLogId(0);
        setPlayerId('');
        setTeam('');
        setQuarterBackId('');
        setActionId('');
        setYardage('');
        setPenaltyId('');
        setPenaltyStatus('');
    }

    const handleEditButton = (log) => {
        setIsEditing(true);

        setLogId(log.event_log_id ? log.event_log_id : "");
        setSchoolId(log.school_id ? log.school_id : "");
        setPlayerId(log.player_id ? log.player_id : "");
        setTeam(log.team ? log.team : "");
        setQuarterBackId(log.quarter_back_id ? log.quarter_back_id : "");
        setActionId(log.event_type_id ? log.event_type_id : "");
        setYardage(log.yardage ? log.yardage : "");
        setPenaltyId(log.penalty_id ? log.penalty_id : "");
        setPenaltyStatus(log.penalty_status ? log.penalty_status : "");
        
    }

    const handleDeleteButton = (log) => {
        setIsEditing(true);
        setIsDeleting(true);

        setLogId(log.event_log_id);
    }

    const handleDelete = async () => {
        const response = await removeLog();

        if (response.status === 201) {
            clearForm();
            setIsDeleting(false);
            setIsEditing(false);
            getLogs(selectedGame.event_id);
        }
    }

    const handleSaveChanges = async () => {
        const response = await editLog();

        if (response.status === 201) {
            clearForm();
            setIsEditing(false);
            getLogs(selectedGame.event_id);
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const response = await addLog();

        if (response.status === 201) {
            clearForm();
            getLogs(selectedGame.event_id);
        } else {
            console.log(response.status);
        }
        
    }

    return (
        <main>
            <div className={isEditing ? 'overlay' : 'd-none'}>
                    
            </div>
            {isDeleting && (
                <div id="confirmation-box">
                    <p className="confirmation-statement">Are you sure you want to delete this log?</p>
                    <p className="warning-message">This will remove all records of this log and will not be retrievable.</p>
                    <div>
                        <div className="button-left">
                            <Button variant="contained" onClick={() => {setIsDeleting(false); setIsEditing(false); clearForm();}}>Cancel</Button>
                        </div>
                        <div className="button-right">
                            <IconButton aria-label="delete" size="large" onClick={handleDelete}>
                                <DeleteIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            {selectedGame && (
                <div className="classic-view">
                    <div id="form-view">
                        {!isDeleting && (
                            <form id="log-form" onSubmit={handleFormSubmit}>
                                <ToggleButtonGroup
                                    className="toggle-group"
                                    value={schoolId}
                                    exclusive
                                    onChange={(event, newSchoolId) => {
                                        if (newSchoolId !== null) {
                                            setPlayerId("");
                                            setQuarterBackId("");
                                            setSchoolId(newSchoolId);
                                        }
                                    }}
                                    aria-label="School Select"
                                >
                                    <ToggleButton value={selectedGame.home_team_id}>
                                        <p>{selectedGame.home_team}</p>
                                    </ToggleButton>
                                    <ToggleButton value={selectedGame.away_team_id}>
                                        <p>{selectedGame.away_team}</p>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <FormControl>
                                    <InputLabel id="team-select-label">Team</InputLabel>
                                    <Select
                                        labelId="team-select-label"
                                        id="team-select"
                                        value={team}
                                        label="Team"
                                        onChange={(event) => {
                                            if (event.target.value !== "offense") {
                                                setQuarterBackId("");
                                            }
                                            setTeam(event.target.value)
                                        }}
                                    >
                                        <MenuItem value={'offense'}>Offense</MenuItem>
                                        <MenuItem value={'defense'}>Defense</MenuItem>
                                        <MenuItem value={'kick-off'}>Kick Off</MenuItem>
                                        <MenuItem value={'kick-return'}>Kick Return</MenuItem>
                                        <MenuItem value={'punt'}>Punt</MenuItem>
                                        <MenuItem value={'punt-return'}>Punt Return</MenuItem>
                                        <MenuItem value={'field-goal-offense'}>Field Goal Offense</MenuItem>
                                        <MenuItem value={'field-goal-defense'}>Field Goal Defense</MenuItem>
                                    </Select>
                                </FormControl>
                                
                                {schoolId !== 0 && (
                                    <FormControl>
                                        <InputLabel id="player-select-label">Player</InputLabel>
                                        <Select
                                            labelId="player-select-label"
                                            label="Player"
                                            value={playerId}
                                            id="player-select"
                                            onChange={(event) => setPlayerId(event.target.value)}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                            {schoolId === selectedGame.home_team_id &&
                                                team1Players.map((player) => (
                                                <MenuItem key={player.player_id} value={player.player_id}>
                                                    #{player.number} {player.first_name} {player.last_name}
                                                </MenuItem>
                                            ))}

                                            {schoolId === selectedGame.away_team_id &&
                                                team2Players.map((player) => (
                                                <MenuItem key={player.player_id} value={player.player_id}>
                                                    #{player.number} {player.first_name} {player.last_name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                )}
                                {/* For Quarterback selection */}
                                {team === 'offense' && (
                                    schoolId !== 0 && (
                                        <FormControl>
                                            <InputLabel id="quarterback-select-label">Quarterback</InputLabel>
                                            <Select
                                                label="Quarterback"
                                                labelId="quarterback-select-label"
                                                value={quarterBackId}
                                                id="quarterback-select"
                                                onChange={(event) => setQuarterBackId(event.target.value)}
                                            >
                                                <MenuItem value={""}>None</MenuItem>
                                                {schoolId === selectedGame.home_team_id &&
                                                    team1Players.map((player) => (
                                                    <MenuItem key={player.player_id} value={player.player_id}>
                                                        #{player.number} {player.first_name} {player.last_name}
                                                    </MenuItem>
                                                ))}
        
                                                {schoolId === selectedGame.away_team_id &&
                                                    team2Players.map((player) => (
                                                    <MenuItem key={player.player_id} value={player.player_id}>
                                                        #{player.number} {player.first_name} {player.last_name}
                                                    </MenuItem>
                                                ))}
        
                                            </Select>
                                        </FormControl>
                                    )
                                )}
                                {actions && (
                                    <FormControl>
                                        <InputLabel id="action-select-label">Action</InputLabel>
                                        <Select
                                            labelId="action-select-label"
                                            label="Action"
                                            value={actionId}
                                            id="action-select"
                                            onChange={(event) => setActionId(event.target.value)}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                            {actions.map(action => (
                                                <MenuItem key={action.event_type_id} value={action.event_type_id}>{action.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <TextField variant="outlined" label="Yards" type="number" className="input" name="yards" value={yardage} onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const newValue = inputValue === '' ? undefined : parseInt(inputValue, 10);
                                    setYardage(newValue);
                                }} />

                                {penalties && (
                                    <FormControl>
                                        <InputLabel id="penalty-select-label">Penalty</InputLabel>
                                        <Select
                                            labelId="penalty-select-label"
                                            label="Penalty"
                                            value={penaltyId}
                                            id="penalty-select"
                                            onChange={(event) => {
                                                setPenaltyId(event.target.value);

                                                if (event.target.value === "") {
                                                    setPenaltyStatus("");
                                                }
                                            }}
                                        >
                                            <MenuItem value={""}>None</MenuItem>
                                            {penalties.map(penalty => (
                                                <MenuItem key={penalty.penalty_id} value={penalty.penalty_id}>{penalty.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                {penaltyId !== '' && (
                                    <ToggleButtonGroup
                                        className="toggle-group"
                                        value={penaltyStatus}
                                        exclusive
                                        onChange={(event, newValue) => {
                                            if (newValue!== null) {
                                              setPenaltyStatus(newValue); // Update the schoolId state when a new value is selected
                                            }
                                        }}
                                    >
                                        <ToggleButton value={'A'}>Accepted</ToggleButton>
                                        <ToggleButton value={'D'}>Declined</ToggleButton>
                                    </ToggleButtonGroup>
                                )}

                                {!isEditing && (
                                    <Button type="submit" variant="contained">Submit Log</Button>
                                )}
                                {isEditing && (
                                    <div className="split-elements">
                                        <Button variant="text" onClick={() => {setIsEditing(false); clearForm();}}>Cancel</Button>
                                        <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
                                    </div>
                                )}
                                    
                                
                                
                            </form>
                        )}
                    </div>
                    <div id="data-view" className={isEditing ? 'disable' : 'normal'}>
                        {logs && (
                            <div id="data-box" className="box-shadow big-data-box">
                                <div>
                                    <h2>Logs</h2>
                                </div>
                                <div>
                                    {logs.map(log => (
                                        <div className="log-div" key={log.event_log_id}>
                                            <div className="log-info">
                                                <p>School: {log.school_name}</p>
                                                <p>Player: {log.player_name}</p>
                                                <p>Team: {log.team}</p>
                                                <p>Quarterback: {log.quarter_back_name}</p>
                                                <p>Action: {log.action_name}</p>
                                                <p>Yards: {log.yardage}</p>
                                                <p>Penalty: {log.penalty_name}</p>
                                                <p>Penalty Status: {log.penalty_status}</p>
                                            </div>
                                            <div className="log-icons-div">
                                                <ButtonGroup variant="contained" size="large" className="log-icons">
                                                    <IconButton aria-label="edit" size="medium" onClick={() => handleEditButton(log)}>
                                                        <EditIcon fontSize="medium" />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" size="medium" onClick={() => handleDeleteButton(log)}>
                                                        <DeleteIcon fontSize="medium" />
                                                    </IconButton>
                                                </ButtonGroup>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </main>
    );
}

export default LogGame;